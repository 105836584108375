<template>
  <div class="stepper">
    <div><div class="stepper__line"></div></div>
    <div>
      <img :src="step == 1 ? activeImgSrc : inActiveImgSrc" alt="Schritt 1" />
    </div>
    <div><div class="stepper__line"></div></div>
    <div>
      <img :src="step == 2 ? activeImgSrc : inActiveImgSrc"  alt="Schritt 2"/>
    </div>
    <div><div class="stepper__line"></div></div>
    <div>
      <img :src="step == 3 ? activeImgSrc : inActiveImgSrc"  alt="Schritt 3"/>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  data() {
    return {};
  },
  props: [],
  name: "Stepper",
  components: {},
  setup() {
    const store = useStore();
    const activeImgSrc = ref(
      require("../../assets/img/icons/active-stepper.svg")
    );
    const inActiveImgSrc = ref(
      require("../../assets/img/icons/inactive-stepper.svg")
    );
    const step = computed(() => {
      return store.getters["cart/getStepper"];
    });

    return {
      step,
      activeImgSrc,
      inActiveImgSrc,
    };
  },
};
</script>

<style lang="postcss" scoped>
.stepper {
  @apply flex text-black flex-row justify-center items-end grid grid-cols-6 gap-2 w-2/6;
  &__step {
    @apply bg-blue text-white rounded-full text-xs py-1 px-3;
  }
  &__line {
    @apply bg-blue ;
  }
  div {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.1rem;
  }
}
</style>
