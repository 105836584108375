<template>
  <section class="page-nav-menu">
    <div class="page-nav-menu__container">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-1">
          <div class="back-button">
            <router-link :to="{}" :title="t('LBL_BACK')" @click="goback()"
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#0046AA" /></svg
              >{{
                `
              ${t("LBL_BACK")}`
              }}</router-link
            >
          </div>
        </div>
        <div class="col-span-1">
          <div class="shopping-cart" v-if="!isCheckoutSuccess">
            <Stepper v-if="isCheckout"></Stepper>
            <shopping-cart-menu v-else :show-text="false"></shopping-cart-menu>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ShoppingCartMenu from "@/components/ShoppingCart/ShoppingCartMenu";
  import Stepper from "@/components/Stepper/Stepper";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { ref } from "vue";
  import { useI18n } from "vue-i18n";

  export default {
    name: "NavMenu",
    components: {
      ShoppingCartMenu,
      Stepper,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const isCheckout = ref(false);
      const isCheckoutSuccess = ref(false);
      const isBargeld = ref(false);

      isCheckout.value = route.name === "shopping-cart" || route.name === "my-shopping-cart" || route.name === "bestellen";

      isCheckoutSuccess.value = route.name === "order-success" || route.name === "bargeld";
      isBargeld.value = route.name === "bargeld-success" || route.name === "bargeld-fail"
      const hasHistory = () => {
        return window.history.length > 2;
      };
      const goback = async () => {
        await store.dispatch("sliderImages/setImages", { data: [], type: "" });
        if (hasHistory()) {
          if (isBargeld.value) {
            await router.push("/bargeld/form");
          } else if (JSON.stringify(window.history.state.back) === 'null') {
            await router.push("/");
          } else {
            router.back();
          }
        } else {
          await router.push(isBargeld.value ? "/bargeld/form" : "/");
        }
      };
      return {
        isCheckoutSuccess,
        isCheckout,
        hasHistory,
        goback,
        t,
      };
    },
  };
</script>
<style lang="postcss" scoped>
  .page-nav-menu {
    @apply bg-white  border-b-8 border-blue-lighter;
    &__container {
      @apply text-gray-dark;
      grid-area: main;
      a {
        @apply ml-10 text-blue;
      }
      .back-button {
        height: 3.5rem;
        margin-left: 0.8rem;
        @apply flex text-black flex-col justify-center items-start;
        a {
          @apply ml-0 flex-row flex items-center justify-start;
        }
      }
      .shopping-cart {
        height: 3.5rem;
        @apply flex text-black flex-col justify-center items-end;
        @media (max-width: 767px) {
          @apply pr-4;
        }
      }
    }
  }
  .notification {
    @apply bg-highlighted;
  }
</style>
