<template>
  <section>
    <nav-menu></nav-menu>
    <product :product="product" :detailed="true"></product>
  </section>
</template>

<script>
import Product from "@/components/Product/Product";
import NavMenu from "@/components/Menu/NavMenu";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ProductDetails",
  components: {
    Product,
    NavMenu,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    let sku = route?.params?.seo_url?.split("_").pop();
    store.dispatch("catalog/getProduct", sku);
    return {
      product: computed(() => {
        return store.getters["catalog/getProduct"];
      }),
    };
  },
};
</script>
